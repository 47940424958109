import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import useWindowSize from "../../utils/useWindowSize"
import Tabs from "../../components/common/Tabs"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import "./about-us-overview-styles.css"

const AboutUsOverviewPage = () => {
  const screen = useWindowSize()

  const prismicAboutUsOverview = useStaticQuery(graphql`
    query AboutUsOverviewQueryUpdate {
      prismicAboutUsOverview {
        data {
          title
          hero_image {
            gatsbyImageData
          }
          contents {
            image_location
            text {
              richText
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const image = getImage(
    prismicAboutUsOverview.prismicAboutUsOverview.data.hero_image
  )

  const { contents } = prismicAboutUsOverview.prismicAboutUsOverview.data

  return (
    <Layout>
      <SEOnew title={prismicAboutUsOverview.prismicAboutUsOverview.title} />
      <div className="w-full bg-site-gray flex overflow-hidden relative lg:rounded-bl-100 lg:h-500 flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 h-full relative flex">
          <StaticImage
            id="cover"
            src="../../images/blank.png"
            quality={95}
            placeholder="DOMINANT_COLOR"
            formats={["AUTO", "WEBP"]}
            alt={`About${prismicAboutUsOverview.prismicAboutUsOverview.title}`}
            className="w-full h-full z-5 top-0 left-0"
            objectFit="cover"
            style={{position: "absolute"}}
          />
          <GatsbyImage
            image={image}
            alt={prismicAboutUsOverview.prismicAboutUsOverview.title}
            style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
            objectPosition={"left"}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-transparent bg-toTopGray lg:bg-toRightGray">
            &nbsp;
          </div>
        </div>
        <div className="w-full lg:w-1/2 pt-12 lg:pt-24 pb-12 px-4 flex flex-col ">
          <div className="p-1 text-site-yellow flex flex-col text-base lg:text-2xl gap-y-4 px-2 md:px-4 h-full justify-center">
            <p>
              "Architecture should be an honest reflection of the owner’s goals
              and expectations while respectfully embracing and balancing the
              demands and benefits of the site. Architecture should enhance the
              quality of life for the client and the environment" - Brion
              Jeannette
            </p>
            <p>
              "Our goal is to create unique architecture in a responsible manner
              – not only for our clients, but also for our environment" - Amy J.
              Creager
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-8 lg:pt-12 mb-12 overview-content">
        <div className="w-full max-w-7xl px-4">
          <Tabs active={0} />
        </div>
        <div className="w-full max-w-7xl px-4 py-8 relative">
          {contents.map((content, idx) => {
            const image = getImage(content.image)
            if (content.image_location == "top") {
              return (
                <>
                  <div className="relative w-full">
                    <StaticImage
                      id="cover"
                      src="../../images/blank.png"
                      quality={95}
                      placeholder="DOMINANT_COLOR"
                      formats={["AUTO", "WEBP"]}
                      alt={`About${prismicAboutUsOverview.prismicAboutUsOverview.title}`}
                      className="w-full h-full z-5 top-0 left-0"
                      objectFit="cover"
                      style={{position: "absolute"}}
                    />
                    <GatsbyImage
                      image={image}
                      alt={`image-${idx}`}
                      className="w-full"
                    />
                  </div>
                  <PrismicRichText
                    field={content.text.richText}
                    components={htmlSerializer}
                  />
                </>
              )
            } else {
              return (
                <>
                  <div
                    className={`relative w-full ${
                      content.image_location === "left"
                        ? "max-w-xs mr-8 float-left"
                        : "max-w-lg ml-8 float-right"
                    }`}
                  >
                    <StaticImage
                      id="cover"
                      src="../../images/blank.png"
                      quality={95}
                      placeholder="DOMINANT_COLOR"
                      formats={["AUTO", "WEBP"]}
                      alt={`About${prismicAboutUsOverview.prismicAboutUsOverview.title}`}
                      className="w-full h-full z-5 top-0 left-0"
                      objectFit="cover"
                      style={{position: "absolute"}}
                    />
                    <GatsbyImage
                      image={image}
                      alt={`image-${idx}`}
                      className={`w-full ${
                        content.image_location === "left"
                          ? "max-w-xs mr-8 float-left"
                          : "max-w-lg ml-8 float-right"
                      }`}
                    />
                  </div>
                  <PrismicRichText
                    field={content.text.richText}
                    components={htmlSerializer}
                  />
                </>
              )
            }
          })}
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default AboutUsOverviewPage
