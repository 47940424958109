import { Link, navigate } from "gatsby"
import React from "react"

const Tabs = ({ active }) => {
  const inActiveStyle =
    "flex-grow lg:flex-grow-0 px-2 lg:px-4 py-2 border-b-2 border-gray-400 text-gray-400 cursor-pointer"
  const activeStyle =
    "flex-grow lg:flex-grow-0 px-2 lg:px-4 py-2 border-l-2 border-gray-400 border-r-2 border-t-2 rounded-t-xl bg-gradient-to-b from-site-yellow to-white cursor-default"

  const goToPage = index => {
    if (index === 0) {
      navigate("/abouts-overview")
    } else if (index === 1) {
      navigate("/abouts/principals")
    } else if (index === 2) {
      navigate("/abouts/keystaff")
    } else if (index === 3) {
      navigate("/fun")
    }
  }

  return (
    <div className="w-full py-4 flex text-sm lg:text-xl">
      <div className="hidden lg:block flex-grow border-b-2 border-gray-400 pl-4">
        About Us
      </div>
      {active === 0 ? (
        <p className={activeStyle}>Overview</p>
      ) : (
        <Link className={inActiveStyle} to="/about-us-overview">
          Overview
        </Link>
      )}
      {active === 1 ? (
        <p className={activeStyle}>Principals</p>
      ) : (
        <Link className={inActiveStyle} to="/about-us-principals">
          Principals
        </Link>
      )}
      {active === 2 ? (
        <p className={activeStyle}>Key Staff</p>
      ) : (
        <Link className={inActiveStyle} to="/about-us-keystaff">
          Key Staff
        </Link>
      )}
      {active === 3 ? (
        <p className={activeStyle}>Fun at BJA</p>
      ) : (
        <Link className={inActiveStyle} to="/fun">
          Fun at BJA
        </Link>
      )}
      <div className="hidden lg:block flex-grow-0 px-4 py-2 border-b-2 border-gray-400">
        &nbsp;
      </div>
    </div>
  )
}

export default Tabs
